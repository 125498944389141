@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&display=swap');
body{
    font-family: 'Popins', sans-serif;
    background-color: #FFFFFF;
}
body::-webkit-scrollbar {
    width: 0.4em;
}
   
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
   
body::-webkit-scrollbar-thumb {
  background-color: #222732;
  outline: 1px solid #222732;
}

@media screen and (max-width: 700px) {
    .tag {   
      /* flex-direction: column;
      float: none; */
      /* float: left !important; */
      overflow: auto !important;
      width: 300px !important;
      /* padding: 50px !important; */
      /* margin: 20px !important; */
      display: flex;
      flex-direction: column;

    }
    .brandName {
      font-weight: 900 !important;
      font-size: 45px !important;
  
    }
    .tagLine {
      font-weight: 800 !important;
      font-size: 15px !important;
    }
}
.v-landing {
  height: 700px;
  background-image: linear-gradient(45deg, rgba(238, 9, 121, 0.75), rgba(255, 106, 0, 0.8)), url('../../Assets/logo_4x.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  display:flex;
  align-items:center;
}

.logoTag {
    margin-left: auto;
    margin-right: auto;
    align-items: center;
}

.bgGradient {
    opacity: 0.8;
    z-index: 50;
}

.bgLogo {
    z-index: -10;
    width: 85%;
    height: auto;
}

.tag {
    float: left;
    position: absolute;
    display: block;
    left: 100px;
    top: 50vh;
    z-index: 1000;
    background-color: #2227328f;
    /* opacity: 20%; */
    padding: 15px;
    color: #FFFFFF;
    /* font-weight: bold; */
    justify-content: space-between;
    width: 1000px;
 }

.brandName {
    color: #ffffff;
    font-weight: 900;
    font-size: 65px;
}

.tagLine{
    color: #ffffff;
    font-weight: 800;
    font-size: 25px;
}