.container1{
    bottom: 0 !important;
    background-image: linear-gradient(190deg, #EE0979 6%, #FF6A00 100%);
    opacity: 0.42;
    color:white; 
    padding:40px;
    border-top:1px solid #4b4b4b;
    bottom: 0;
    width: 100%;
    height: 150px;
}

.col-md-6:hover {
    color: #831b01;
}