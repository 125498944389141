body{
    font-family: 'Popins', sans-serif;
    background-color: #ffffff;
}
body::-webkit-scrollbar {
    width: 0.4em;
  }
   
  body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  body::-webkit-scrollbar-thumb {
    background-color: #222732;
    outline: 1px solid #222732;
  }

.mov {
    position: relative;
    margin-left: 120px;
    margin-right: 120px;
    height: 112px;
}

.brand {
  color: #222 !important;
  display: inline-flex;
}

.site-title-wrap {
  padding-left: 15px;
  padding-top: 5px;
  vertical-align: middle;
}

.site-title {
  font-size: 1.5333333333rem;
  font-weight: 700;
  text-transform: none;
  vertical-align: middle;
}

.site-description {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 0;
  vertical-align: middle;
}

.basic-navbar-nav {
  justify-content: flex-end;
  padding-right: 20px;
  height: 100%;
  padding-inline-start: 40px;
}

.link {
  color: #222732 !important;
  padding: 1em 2em;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 4;
}

.link:hover {
  color: #ee0979 !important;
}

.link-contact {
  color: #ffffff;
  background-color: #ee0979;
  line-height: 1;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 100px;
  border-style: solid;
  border-color: #ee0979;
  border-top-width: 8px;
  border-right-width: 8px;
  border-left-width: 8px;
  border-bottom-width: 8px;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  margin-left: 10px;
  margin-right: 0;
}

.link-contact:hover {
  border-color: #ff6a00;
  background-color: #ee0979;
}