/* Navigation Menu CSS */

ul.hfe-nav-menu,
.hfe-nav-menu li,
.hfe-nav-menu ul {
    list-style: none !important;
    margin: 0;
    padding: 0;
}

.hfe-nav-menu li.menu-item {
    position: relative;
}

.hfe-flyout-container .hfe-nav-menu li.menu-item {
    position: relative;
    background: unset;
}

.hfe-nav-menu .sub-menu li.menu-item {
    position: relative;
    background: inherit;
}

div.hfe-nav-menu,
.elementor-widget-hfe-nav-menu .elementor-widget-container {
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.hfe-nav-menu__layout-horizontal,
.hfe-nav-menu__layout-horizontal .hfe-nav-menu {
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}

.hfe-nav-menu__layout-horizontal .hfe-nav-menu {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.hfe-nav-menu .parent-has-no-child .hfe-menu-toggle {
    display: none;
}

.hfe-nav-menu__layout-horizontal .hfe-nav-menu .sub-menu,
.hfe-submenu-action-hover .hfe-layout-vertical .hfe-nav-menu .sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 16;
    visibility: hidden;
    opacity: 0;
    text-align: left;
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in;
}

.hfe-nav-menu__layout-horizontal .hfe-nav-menu .menu-item-has-children:hover > .sub-menu,
.hfe-nav-menu__layout-horizontal .hfe-nav-menu .menu-item-has-children:focus > .sub-menu {
    visibility: visible;
    opacity: 1;
}

.hfe-submenu-action-click .hfe-nav-menu .menu-item-has-children:hover > .sub-menu,
.hfe-submenu-action-click .hfe-nav-menu .menu-item-has-children:focus > .sub-menu {
    visibility: hidden;
    opacity: 0;
}

.hfe-nav-menu .menu-item-has-children .sub-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
}

.hfe-flyout-container .hfe-nav-menu .menu-item-has-children .menu-item-has-children .sub-menu {
    top: 0;
    left: 0;
}

.hfe-nav-menu .menu-item-has-children .menu-item-has-children .sub-menu {
    top: 0;
    left: 100%;
}

.hfe-nav-menu__layout-horizontal .hfe-nav-menu .menu-item-has-children:hover > .sub-menu,
.hfe-nav-menu__layout-horizontal .hfe-nav-menu .menu-item-has-children.focus > .sub-menu {
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
}

.hfe-nav-menu:before,
.hfe-nav-menu:after {
    content: '';
    display: table;
    clear: both;
}


/* Alignemnt CSS */
.hfe-nav-menu__align-right .hfe-nav-menu {
  margin-left: auto;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          -webkit-justify-content: flex-end;
          -moz-box-pack: end;
          justify-content: flex-end; }

.hfe-nav-menu__align-right .hfe-nav-menu__layout-vertical li a.hfe-menu-item,
.hfe-nav-menu__align-right .hfe-nav-menu__layout-vertical li a.hfe-sub-menu-item {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          -webkit-justify-content: flex-end;
          -moz-box-pack: end;
          justify-content: flex-end; }
.hfe-nav-menu__align-right .hfe-nav-menu__layout-vertical li.elementor-button-wrapper{
    text-align: right;
}
.hfe-nav-menu__align-left .hfe-nav-menu__layout-vertical li.elementor-button-wrapper{
    text-align: left;
}
.hfe-nav-menu__align-center .hfe-nav-menu__layout-vertical li.elementor-button-wrapper{
    text-align: center;
}
.hfe-nav-menu__align-left .hfe-nav-menu {
  margin-right: auto;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          -moz-box-pack: start;
          justify-content: flex-start; }

.hfe-nav-menu__align-left .hfe-nav-menu__layout-vertical li a.hfe-menu-item,
.hfe-nav-menu__align-left .hfe-nav-menu__layout-vertical li a.hfe-sub-menu-item {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          -webkit-justify-content: flex-start;
          -moz-box-pack: start;
          justify-content: flex-start; }

.hfe-nav-menu__align-center .hfe-nav-menu {
  margin-left: auto;
  margin-right: auto;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          -webkit-justify-content: center;
          -moz-box-pack: center;
          justify-content: center; }

.hfe-nav-menu__align-center .hfe-nav-menu__layout-vertical li a.hfe-menu-item,
.hfe-nav-menu__align-center .hfe-nav-menu__layout-vertical li a.hfe-sub-menu-item {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          -webkit-justify-content: center;
          -moz-box-pack: center;
          justify-content: center; }

.hfe-nav-menu__layout-horizontal .hfe-nav-menu {
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; 
}
.hfe-nav-menu__align-justify .hfe-nav-menu__layout-horizontal .hfe-nav-menu {
    width: 100%; 
}
  .hfe-nav-menu__align-justify .hfe-nav-menu__layout-horizontal .hfe-nav-menu li.menu-item {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            -webkit-flex-grow: 1;
            -moz-box-flex: 1;
            flex-grow: 1; }
    .hfe-nav-menu__align-justify .hfe-nav-menu__layout-horizontal .hfe-nav-menu li a.hfe-menu-item {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              -webkit-justify-content: center;
              -moz-box-pack: center;
              justify-content: center; }

/* Alignment CSS ends */

.hfe-active-menu + nav li:not(:last-child) a.hfe-menu-item,
.hfe-active-menu + nav li:not(:last-child) a.hfe-sub-menu-item {
    border-bottom-width: 0;
}

/*  Horizontal Menu */

.hfe-nav-menu a.hfe-menu-item,
.hfe-nav-menu a.hfe-sub-menu-item {
    line-height: 1;
    text-decoration: none;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.hfe-nav-menu a.hfe-menu-item:hover,
.hfe-nav-menu a.hfe-sub-menu-item:hover {
    text-decoration: none;
}

.hfe-nav-menu .sub-menu {
    min-width: 220px;
    margin: 0;
    z-index: 9999;
}

.hfe-nav-menu__layout-horizontal {
    font-size: 0;
}

.hfe-nav-menu__layout-horizontal li.menu-item,
.hfe-nav-menu__layout-horizontal > li.menu-item {
    font-size: medium;
}

.hfe-nav-menu__layout-horizontal .hfe-nav-menu .sub-menu{
    -webkit-box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 10px -2px rgba(0, 0, 0, 0.1);
}

/* Icons CSS */
.hfe-submenu-icon-arrow .hfe-nav-menu .parent-has-child .sub-arrow i:before {
    content: ''; 
}
.hfe-submenu-icon-classic .hfe-nav-menu .parent-has-child .sub-arrow i:before {
    content: ''; 
}
.hfe-submenu-icon-plus .hfe-nav-menu .parent-has-child .sub-arrow i:before {
    content: '+'; 
}
.hfe-submenu-icon-none .hfe-nav-menu .parent-has-child .sub-arrow {
    display: none; 
}

.hfe-submenu-icon-arrow .hfe-nav-menu .parent-has-child .sub-menu-active .sub-arrow i:before {
    content: ''; 
}
.hfe-submenu-icon-plus .hfe-nav-menu .parent-has-child .sub-menu-active .sub-arrow i:before {
    content: '-'; 
}
.hfe-submenu-icon-classic .hfe-nav-menu .parent-has-child .sub-menu-active .sub-arrow i:before {
    content: ''; 
}

.rtl .hfe-submenu-icon-arrow .hfe-nav-menu__layout-horizontal .menu-item-has-children ul a .sub-arrow i:before {
    content: ''; 
}

.rtl .hfe-submenu-icon-classic .hfe-nav-menu__layout-horizontal .menu-item-has-children ul a .sub-arrow i:before {
    content: ''; 
}

.hfe-submenu-icon-arrow .hfe-nav-menu__layout-horizontal .menu-item-has-children ul a .sub-arrow i:before {
    content: ''; 
}

.hfe-submenu-icon-classic .hfe-nav-menu__layout-horizontal .menu-item-has-children ul a .sub-arrow i:before {
    content: ''; 
}
/* Icon CSS ends */

.hfe-nav-menu-icon {
    padding: 0.35em;
    border: 0 solid;
}

.hfe-nav-menu-icon svg {
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 25px;
}

.hfe-nav-menu-icon i:focus {
    outline: 0;
}

.hfe-has-submenu-container a.hfe-menu-item:focus,
.hfe-has-submenu-container a.hfe-sub-menu-item:focus {
    outline: 0;
}
/* Submenus */

.hfe-nav-menu .menu-item-has-children:focus,
.hfe-nav-menu .menu-item-has-children .sub-menu:focus,
.hfe-nav-menu .menu-item-has-children-container:focus {
    outline: 0;
}
.hfe-nav-menu .menu-item-has-children-container {
    position: relative;
}

.hfe-nav-menu__layout-expandible .sub-menu,
.hfe-nav-menu__layout-vertical .sub-menu,
.hfe-flyout-wrapper .sub-menu {
    position: relative;
    height: 0;
}

.hfe-nav-menu__submenu-arrow .hfe-menu-toggle:before,
.hfe-nav-menu__submenu-plus .hfe-menu-toggle:before,
.hfe-nav-menu__submenu-classic .hfe-menu-toggle:before {
    font-family: 'Font Awesome 5 Free';
    z-index: 1;
    font-size: inherit;
    font-weight: inherit;
    line-height: 0;
}

.hfe-menu-item-center .hfe-nav-menu li a.hfe-menu-item,
.hfe-menu-item-center .hfe-nav-menu li a.hfe-sub-menu-item {
    margin-right: 0px;
}

.hfe-nav-menu__submenu-none .hfe-nav-menu li a.hfe-menu-item,
.hfe-nav-menu__submenu-none .hfe-nav-menu li a.hfe-sub-menu-item {
    margin-right: 0px;
}

.hfe-nav-menu__toggle-icon:before {
    content: '\f0c9';
    font-family: 'fontAwesome';
    z-index: 1;
    font-size: 24px;
    font-weight: inherit;
    font-style: normal;
    line-height: 0;
}

.hfe-active-menu .hfe-nav-menu__toggle-icon:before {
    content: '\f00d';
}

.hfe-nav-menu__layout-expandible {
    -webkit-transition-property: display;
    transition-property: display;
    -webkit-transition-duration: .5s;
    transition-duration: .5s;
    -webkit-transition-timing-function: cubic-bezier( 0, 1, 0.5, 1 );
    transition-timing-function: cubic-bezier( 0, 1, 0.5, 1 );
}

.hfe-nav-menu__layout-expandible {
    visibility: hidden;
    opacity: 0; 
}

.hfe-nav-menu__layout-expandible .sub-menu {
    visibility: hidden;
    opacity: 0; 
}

.hfe-active-menu + .hfe-nav-menu__layout-expandible {
    visibility: visible;
    opacity: 1; 
    height: auto;
}

.hfe-active-menu.hfe-active-menu-full-width + .hfe-nav-menu__layout-expandible,
.hfe-active-menu.hfe-active-menu-full-width + .hfe-nav-menu__layout-horizontal,
.hfe-active-menu.hfe-active-menu-full-width + .hfe-nav-menu__layout-vertical {
    position: absolute;
    top: 100%;
}

.hfe-nav-menu__layout-expandible .menu-item-has-children .menu-item-has-children .sub-menu,
.hfe-nav-menu__layout-vertical .menu-item-has-children .menu-item-has-children .sub-menu {
    top: 0;
    left: 0;
}

.hfe-layout-expandible nav {
    height: 0;
}

.hfe-nav-sub-menu-icon, .hfe-nav-menu__icon {
    display: inline-block;
    line-height: 1;
}

.hfe-nav-menu__toggle {
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 22px;
    cursor: pointer;
    border: 0 solid;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    color: #494c4f;
    position: relative;
    line-height: 1;
}

.hfe-nav-menu__toggle-icon {
    display: inline-block;
    font-size: inherit;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hfe-nav-menu .sub-arrow {
    font-size: 14px;
    line-height: 1;
    -js-display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    margin-top: -10px;
    margin-bottom: -10px;
}

.hfe-nav-menu__layout-horizontal .hfe-nav-menu .sub-arrow {
    margin-left: 10px;
}

.hfe-nav-menu__layout-vertical .hfe-nav-menu .sub-arrow,
.hfe-layout-expandible .sub-arrow,
.hfe-flyout-wrapper .sub-arrow
{
    padding: 10px;
}

.hfe-nav-menu .child-item,
.hfe-nav-menu__layout-vertical .hfe-nav-menu .child-item {
    font-size: 14px;
    line-height: 1;
    padding: 10px 0 10px 10px;
    margin-top: -10px;
    margin-bottom: -10px;
    position: absolute;
    right: 0;
    margin-right: 20px;
}

.hfe-layout-horizontal .hfe-nav-menu__toggle,
.hfe-layout-vertical .hfe-nav-menu__toggle {
    visibility: hidden;
    opacity: 0;
    display: none;
}

.hfe-nav-menu-icon {
    display: inline-block;
    line-height: 1;
    text-align: center;
}

.hfe-nav-menu__layout-expandible .saved-content,
.hfe-nav-menu__layout-expandible .sub-menu,
.hfe-nav-menu__layout-expandible .child,
.hfe-nav-menu__layout-vertical .saved-content,
.hfe-nav-menu__layout-vertical .sub-menu,
.hfe-nav-menu__layout-vertical .child,
.hfe-flyout-container .saved-content,
.hfe-flyout-container .sub-menu,
.hfe-flyout-container .child {
    left: 0 !important;
    width: 100% !important; 
}

.hfe-flyout-container .hfe-nav-menu .sub-menu {
    background: unset;
}

.hfe-nav-menu__layout-vertical .hfe-menu-toggle:hover,
.hfe-nav-menu__layout-expandible .hfe-menu-toggle:hover,
.hfe-flyout-wrapper .hfe-menu-toggle:hover {
    border:1px dotted;
}

.hfe-nav-menu__layout-vertical .hfe-menu-toggle,
.hfe-nav-menu__layout-expandible .hfe-menu-toggle,
.hfe-flyout-wrapper .hfe-menu-toggle {
    border:1px dotted transparent;
}

/* Common Animation CSS */

.hfe-nav-menu .menu-item a:before,
.hfe-nav-menu .menu-item a:after {
  display: block;
  position: absolute;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);
          transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1); }

.hfe-nav-menu .menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-nav-menu .menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after {
  opacity: 0; }

.hfe-nav-menu .menu-item a.hfe-menu-item:hover:before,
.hfe-nav-menu .menu-item a.hfe-menu-item:hover:after,
.hfe-nav-menu .menu-item a.hfe-menu-item:focus:before,
.hfe-nav-menu .menu-item a.hfe-menu-item:focus:after,
.hfe-nav-menu .menu-item a.hfe-menu-item.highlighted:before,
.hfe-nav-menu .menu-item a.hfe-menu-item.highlighted:after,
.hfe-nav-menu .current-menu-item:before,
.hfe-nav-menu .current-menu-item:after {
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}

/* Double Line / Underline / Overline Style */

.hfe-pointer__double-line .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__double-line .menu-item.parent a.hfe-menu-item:after, .hfe-pointer__underline .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__underline .menu-item.parent a.hfe-menu-item:after, .hfe-pointer__overline .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__overline .menu-item.parent a.hfe-menu-item:after {
    height: 3px;
    width: 100%;
    left: 0;
    background-color: #55595c;
    z-index: 2;
}

.hfe-pointer__double-line.hfe-animation__grow .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__double-line.hfe-animation__grow .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__underline.hfe-animation__grow .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__underline.hfe-animation__grow .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__overline.hfe-animation__grow .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__overline.hfe-animation__grow .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after {
    height: 0;
    width: 0;
    left: 50%;
}

.hfe-pointer__double-line.hfe-animation__drop-out .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__underline.hfe-animation__drop-out .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__overline.hfe-animation__drop-out .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    top: 10px;
}

.hfe-pointer__double-line.hfe-animation__drop-out .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__underline.hfe-animation__drop-out .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__overline.hfe-animation__drop-out .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after {
    bottom: 10px;
}

.hfe-pointer__double-line.hfe-animation__drop-in .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__underline.hfe-animation__drop-in .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__overline.hfe-animation__drop-in .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    top: -10px;
}

.hfe-pointer__double-line.hfe-animation__drop-in .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__underline.hfe-animation__drop-in .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__overline.hfe-animation__drop-in .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after {
    bottom: -10px;
}

.hfe-pointer__double-line.hfe-animation__none, .hfe-pointer__double-line.hfe-animation__none .menu-item.parent a.hfe-menu-item, .hfe-pointer__underline.hfe-animation__none, .hfe-pointer__underline.hfe-animation__none .menu-item.parent a.hfe-menu-item, .hfe-pointer__overline.hfe-animation__none, .hfe-pointer__overline.hfe-animation__none .menu-item.parent a.hfe-menu-item {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
}

.hfe-pointer__double-line.hfe-animation__none:before, .hfe-pointer__double-line.hfe-animation__none:after, .hfe-pointer__double-line.hfe-animation__none:hover, .hfe-pointer__double-line.hfe-animation__none:focus, .hfe-pointer__double-line.hfe-animation__none .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__double-line.hfe-animation__none .menu-item.parent a.hfe-menu-item:after, .hfe-pointer__double-line.hfe-animation__none .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__double-line.hfe-animation__none .menu-item.parent a.hfe-menu-item:focus, .hfe-pointer__underline.hfe-animation__none:before, .hfe-pointer__underline.hfe-animation__none:after, .hfe-pointer__underline.hfe-animation__none:hover, .hfe-pointer__underline.hfe-animation__none:focus, .hfe-pointer__underline.hfe-animation__none .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__underline.hfe-animation__none .menu-item.parent a.hfe-menu-item:after, .hfe-pointer__underline.hfe-animation__none .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__underline.hfe-animation__none .menu-item.parent a.hfe-menu-item:focus, .hfe-pointer__overline.hfe-animation__none:before, .hfe-pointer__overline.hfe-animation__none:after, .hfe-pointer__overline.hfe-animation__none:hover, .hfe-pointer__overline.hfe-animation__none:focus, .hfe-pointer__overline.hfe-animation__none .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__overline.hfe-animation__none .menu-item.parent a.hfe-menu-item:after, .hfe-pointer__overline.hfe-animation__none .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__overline.hfe-animation__none .menu-item.parent a.hfe-menu-item:focus {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
}

.hfe-pointer__double-line .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__overline .menu-item.parent a.hfe-menu-item:before {
    content: '';
    top: 0;
}

.hfe-pointer__double-line.hfe-animation__slide .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__overline.hfe-animation__slide .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    width: 10px;
    left: -20px;
}

.hfe-pointer__double-line .menu-item.parent a.hfe-menu-item:after, .hfe-pointer__underline .menu-item.parent a.hfe-menu-item:after {
    content: '';
    bottom: 0;
}

.hfe-pointer__double-line.hfe-animation__slide .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__underline.hfe-animation__slide .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after {
    width: 10px;
    left: -webkit-calc( 100% + 20px);
    left: calc( 100% + 20px);
}


/* Framed Style */

.hfe-pointer__framed .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__framed .menu-item.parent a.hfe-menu-item:after {
    background: transparent;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border: 3px solid #55595c;
}

.hfe-pointer__framed .menu-item.parent a.hfe-menu-item:before {
    content: '';
}

.hfe-pointer__framed.hfe-animation__grow .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
}

.hfe-pointer__framed.hfe-animation__shrink .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    -webkit-transform: scale(1.25);
    -ms-transform: scale(1.25);
    transform: scale(1.25);
}

.hfe-pointer__framed.hfe-animation__grow .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__framed.hfe-animation__shrink .menu-item.parent a.hfe-menu-item:before {
    -webkit-transition: opacity 0.2s, -webkit-transform 0.4s;
    transition: opacity 0.2s, -webkit-transform 0.4s;
    transition: opacity 0.2s, transform 0.4s;
    transition: opacity 0.2s, transform 0.4s, -webkit-transform 0.4s;
}

.hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item:after {
    width: 3px;
    height: 3px;
}

.hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item:before {
    border-width: 0 0 3px 3px;
    -webkit-transition: width 0.1s 0.2s, height 0.1s 0.3s, opacity 0.12s 0.22s;
    transition: width 0.1s 0.2s, height 0.1s 0.3s, opacity 0.12s 0.22s;
}

.hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item:after {
    content: '';
    top: initial;
    bottom: 0;
    left: initial;
    right: 0;
    border-width: 3px 3px 0 0;
    -webkit-transition: width 0.1s, height 0.1s 0.1s, opacity 0.02s 0.18s;
    transition: width 0.1s, height 0.1s 0.1s, opacity 0.02s 0.18s;
}

.hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item:hover:before, .hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item:hover:after, .hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item:focus:before, .hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item:focus:after, .hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item.highlighted:before, .hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item.highlighted:after, .hfe-pointer__framed.hfe-animation__draw .current-menu-item:before, .hfe-pointer__framed.hfe-animation__draw .current-menu-item:after {
    width: 100%;
    height: 100%;
}

.hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item:hover:before, .hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item:focus:before, .hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item.highlighted:before, .hfe-pointer__framed.hfe-animation__draw .current-menu-item:before {
    -webkit-transition: opacity 0.02s, height 0.1s, width 0.1s 0.1s;
    transition: opacity 0.02s, height 0.1s, width 0.1s 0.1s;
}

.hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item:hover:after, .hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item:focus:after, .hfe-pointer__framed.hfe-animation__draw .menu-item.parent a.hfe-menu-item.highlighted:after, .hfe-pointer__framed.hfe-animation__draw .current-menu-item:after {
    -webkit-transition: opacity 0.02s 0.2s, height 0.1s 0.2s, width 0.1s 0.3s;
    transition: opacity 0.02s 0.2s, height 0.1s 0.2s, width 0.1s 0.3s;
}

.hfe-pointer__framed.hfe-animation__corners .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__framed.hfe-animation__corners .menu-item.parent a.hfe-menu-item:after {
    width: 3px;
    height: 3px;
}

.hfe-pointer__framed.hfe-animation__corners .menu-item.parent a.hfe-menu-item:before {
    border-width: 3px 0 0 3px;
}

.hfe-pointer__framed.hfe-animation__corners .menu-item.parent a.hfe-menu-item:after {
    content: '';
    top: initial;
    bottom: 0;
    left: initial;
    right: 0;
    border-width: 0 3px 3px 0;
}

.hfe-pointer__framed.hfe-animation__corners .menu-item.parent a.hfe-menu-item:hover:before, .hfe-pointer__framed.hfe-animation__corners .menu-item.parent a.hfe-menu-item:hover:after, .hfe-pointer__framed.hfe-animation__corners .menu-item.parent a.hfe-menu-item:focus:before, .hfe-pointer__framed.hfe-animation__corners .menu-item.parent a.hfe-menu-item:focus:after, .hfe-pointer__framed.hfe-animation__corners .menu-item.parent a.hfe-menu-item.highlighted:before, .hfe-pointer__framed.hfe-animation__corners .menu-item.parent a.hfe-menu-item.highlighted:after, .hfe-pointer__framed.hfe-animation__corners .current-menu-item:before, .hfe-pointer__framed.hfe-animation__corners .current-menu-item:after {
    width: 100%;
    height: 100%;
    -webkit-transition: opacity 0.002s, width 0.4s, height 0.4s;
    transition: opacity 0.002s, width 0.4s, height 0.4s;
}

.hfe-pointer__framed.hfe-animation__none, .hfe-pointer__framed.hfe-animation__none .menu-item.parent a.hfe-menu-item {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
}

.hfe-pointer__framed.hfe-animation__none:before, .hfe-pointer__framed.hfe-animation__none:after, .hfe-pointer__framed.hfe-animation__none:hover, .hfe-pointer__framed.hfe-animation__none:focus, .hfe-pointer__framed.hfe-animation__none .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__framed.hfe-animation__none .menu-item.parent a.hfe-menu-item:after, .hfe-pointer__framed.hfe-animation__none .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__framed.hfe-animation__none .menu-item.parent a.hfe-menu-item:focus {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
}

/* Background Style */

.hfe-pointer__background .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__background .menu-item.parent a.hfe-menu-item:after {
    content: '';
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.hfe-pointer__background .menu-item.parent a.hfe-menu-item:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #55595c;
    z-index: -1;
}

.hfe-pointer__background .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__background .menu-item.parent a.hfe-menu-item:focus, .hfe-pointer__background .menu-item.parent a.hfe-menu-item.highlighted, .hfe-pointer__background .current-menu-item {
    color: #fff;
}

.hfe-pointer__background.hfe-animation__grow .menu-item.parent a.hfe-menu-item:before {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
}

.hfe-pointer__background.hfe-animation__grow .menu-item.parent a.hfe-menu-item:hover:before, .hfe-pointer__background.hfe-animation__grow .menu-item.parent a.hfe-menu-item:focus:before, .hfe-pointer__background.hfe-animation__grow .menu-item.parent a.hfe-menu-item.highlighted:before, .hfe-pointer__background.hfe-animation__grow .current-menu-item:before {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

.hfe-pointer__background.hfe-animation__shrink .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.hfe-pointer__background.hfe-animation__shrink .menu-item.parent a.hfe-menu-item:hover:before, .hfe-pointer__background.hfe-animation__shrink .menu-item.parent a.hfe-menu-item:focus:before, .hfe-pointer__background.hfe-animation__shrink .menu-item.parent a.hfe-menu-item.highlighted:before, .hfe-pointer__background.hfe-animation__shrink .current-menu-item:before {
    -webkit-transition: opacity 0.15s, -webkit-transform 0.4s;
    transition: opacity 0.15s, -webkit-transform 0.4s;
    transition: opacity 0.15s, transform 0.4s;
    transition: opacity 0.15s, transform 0.4s, -webkit-transform 0.4s;
}

.hfe-pointer__background.hfe-animation__sweep-left .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    left: 100%;
}

.hfe-pointer__background.hfe-animation__sweep-right .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    right: 100%;
}

.hfe-pointer__background.hfe-animation__sweep-up .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    top: 100%;
}

.hfe-pointer__background.hfe-animation__sweep-down .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    bottom: 100%;
}

.hfe-pointer__background.hfe-animation__shutter-out-vertical .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    bottom: 50%;
    top: 50%;
}

.hfe-pointer__background.hfe-animation__shutter-out-horizontal .menu-item.parent a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    right: 50%;
    left: 50%;
}

.hfe-pointer__background.hfe-animation__shutter-in-vertical .menu-item.parent a.hfe-menu-item:after, .hfe-pointer__background.hfe-animation__shutter-in-horizontal .menu-item.parent a.hfe-menu-item:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #55595c;
    z-index: -1;
}

.hfe-pointer__background.hfe-animation__shutter-in-vertical .menu-item.parent a.hfe-menu-item:before {
    top: 0;
    bottom: 100%;
}

.hfe-pointer__background.hfe-animation__shutter-in-vertical .menu-item.parent a.hfe-menu-item:after {
    top: 100%;
    bottom: 0;
}

.hfe-pointer__background.hfe-animation__shutter-in-vertical .menu-item.parent a.hfe-menu-item:hover:before, .hfe-pointer__background.hfe-animation__shutter-in-vertical .menu-item.parent a.hfe-menu-item:focus:before, .hfe-pointer__background.hfe-animation__shutter-in-vertical .menu-item.parent a.hfe-menu-item.highlighted:before, .hfe-pointer__background.hfe-animation__shutter-in-vertical .current-menu-item:before {
    bottom: 50%;
}

.hfe-pointer__background.hfe-animation__shutter-in-vertical .menu-item.parent a.hfe-menu-item:hover:after, .hfe-pointer__background.hfe-animation__shutter-in-vertical .menu-item.parent a.hfe-menu-item:focus:after, .hfe-pointer__background.hfe-animation__shutter-in-vertical .menu-item.parent a.hfe-menu-item.highlighted:after, .hfe-pointer__background.hfe-animation__shutter-in-vertical .current-menu-item:after {
    top: 50%;
}

.hfe-pointer__background.hfe-animation__shutter-in-horizontal .menu-item.parent a.hfe-menu-item:before {
    right: 0;
    left: 100%;
}

.hfe-pointer__background.hfe-animation__shutter-in-horizontal .menu-item.parent a.hfe-menu-item:after {
    right: 100%;
    left: 0;
}

.hfe-pointer__background.hfe-animation__shutter-in-horizontal .menu-item.parent a.hfe-menu-item:hover:before, .hfe-pointer__background.hfe-animation__shutter-in-horizontal .menu-item.parent a.hfe-menu-item:focus:before, .hfe-pointer__background.hfe-animation__shutter-in-horizontal .menu-item.parent a.hfe-menu-item.highlighted:before, .hfe-pointer__background.hfe-animation__shutter-in-horizontal .current-menu-item:before {
    left: 50%;
}

.hfe-pointer__background.hfe-animation__shutter-in-horizontal .menu-item.parent a.hfe-menu-item:hover:after, .hfe-pointer__background.hfe-animation__shutter-in-horizontal .menu-item.parent a.hfe-menu-item:focus:after, .hfe-pointer__background.hfe-animation__shutter-in-horizontal .menu-item.parent a.hfe-menu-item.highlighted:after, .hfe-pointer__background.hfe-animation__shutter-in-horizontal .current-menu-item:after {
    right: 50%;
}

.hfe-pointer__background.hfe-animation__none, .hfe-pointer__background.hfe-animation__none .menu-item.parent a.hfe-menu-item {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
}

.hfe-pointer__background.hfe-animation__none:before, .hfe-pointer__background.hfe-animation__none:after, .hfe-pointer__background.hfe-animation__none:hover, .hfe-pointer__background.hfe-animation__none:focus, .hfe-pointer__background.hfe-animation__none .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__background.hfe-animation__none .menu-item.parent a.hfe-menu-item:after, .hfe-pointer__background.hfe-animation__none .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__background.hfe-animation__none .menu-item.parent a.hfe-menu-item:focus {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
}

/* Text Style */

.hfe-pointer__text.hfe-animation__skew .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__text.hfe-animation__skew .menu-item.parent a.hfe-menu-item:focus {
    -webkit-transform: skew(-8deg);
    -ms-transform: skew(-8deg);
    transform: skew(-8deg);
}

.hfe-pointer__text.hfe-animation__grow .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__text.hfe-animation__grow .menu-item.parent a.hfe-menu-item:focus {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.hfe-pointer__text.hfe-animation__shrink .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__text.hfe-animation__shrink .menu-item.parent a.hfe-menu-item:focus {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
}

.hfe-pointer__text.hfe-animation__float .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__text.hfe-animation__float .menu-item.parent a.hfe-menu-item:focus {
    -webkit-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    transform: translateY(-8px);
}

.hfe-pointer__text.hfe-animation__sink .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__text.hfe-animation__sink .menu-item.parent a.hfe-menu-item:focus {
    -webkit-transform: translateY(8px);
    -ms-transform: translateY(8px);
    transform: translateY(8px);
}

.hfe-pointer__text.hfe-animation__rotate .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__text.hfe-animation__rotate .menu-item.parent a.hfe-menu-item:focus {
    -webkit-transform: rotate(6deg);
    -ms-transform: rotate(6deg);
    transform: rotate(6deg);
}

.hfe-pointer__text.hfe-animation__none, .hfe-pointer__text.hfe-animation__none .menu-item.parent a.hfe-menu-item {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
}

.hfe-pointer__text.hfe-animation__none:before, .hfe-pointer__text.hfe-animation__none:after, .hfe-pointer__text.hfe-animation__none:hover, .hfe-pointer__text.hfe-animation__none:focus, .hfe-pointer__text.hfe-animation__none .menu-item.parent a.hfe-menu-item:before, .hfe-pointer__text.hfe-animation__none .menu-item.parent a.hfe-menu-item:after, .hfe-pointer__text.hfe-animation__none .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__text.hfe-animation__none .menu-item.parent a.hfe-menu-item:focus {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
}

.hfe-flyout-overlay {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999998;
    background: rgba(0,0,0,.6);
    cursor: pointer;
    -webkit-backface-visibility: hidden;
}

/* No link hover effect to last menu item */

.hfe-pointer__underline .menu-item.parent a.hfe-menu-item.elementor-button:after,
.hfe-pointer__overline .menu-item.parent a.hfe-menu-item.elementor-button:before,
.hfe-pointer__double-line .menu-item.parent a.hfe-menu-item.elementor-button:after,
.hfe-pointer__double-line .menu-item.parent a.hfe-menu-item.elementor-button:before {
    height: 0px;
}

.hfe-pointer__framed .menu-item.parent a.hfe-menu-item.elementor-button:before,
.hfe-pointer__framed .menu-item.parent a.hfe-menu-item.elementor-button:after{
    border-width: 0px;
}

.hfe-pointer__text.hfe-animation__grow .menu-item.parent a.hfe-menu-item.elementor-button:hover,
.hfe-pointer__text.hfe-animation__grow .menu-item.parent a.hfe-menu-item.elementor-button:focus
{
    -webkit-transform: none;
    transform: none;
}

.hfe-flyout-close,
.hfe-flyout-close svg {
    position: absolute;
    top: 0;
    right: 0;
    margin: 5px;
    width: 23px;
    height: 23px;
    line-height: 23px;
    font-size: 23px;
    display: block;
    cursor: pointer;
}

.hfe-flyout-wrapper .hfe-side.hfe-flyout-left {
    top: 0;
    bottom: 0;
    left: -100%;
    right: auto;
    height: 100%;
    /*height: 100vh;*/
    width: 300px;
    max-width: 100%;
    max-width: 100vw;
}

.hfe-flyout-wrapper .hfe-side {
    display: none;
    position: fixed;
    z-index: 999999;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    -webkit-backface-visibility: hidden;
}

.hfe-flyout-wrapper .hfe-side.hfe-flyout-show {
    display: block;
}

.hfe-flyout-content.push {
    color: #ffffff;
}

.hfe-flyout-content ul li {
    color: #ffffff;
}

/* ===========================================================
 *  Flyout Location
 * ======================================================== */

.hfe-flyout-wrapper .hfe-side.hfe-flyout-right {
    top: 0;
    bottom: 0;
    left: auto;
    right: -100%; /* default */
    height: 100%;
    /*height: 100vh;*/
    width: 300px; /* default */
    max-width: 100%;
}

.hfe-flyout-wrapper .hfe-side.hfe-flyout-left {
    top: 0;
    bottom: 0;
    left: -100%; /* default */
    right: auto;
    height: 100%;
    /*height: 100vh;*/
    width: 300px; /* default */
    max-width: 100%;
    max-width: 100vw;
}

.hfe-flyout-wrapper .hfe-side.top {
    top: -100%; /* default */
    bottom: auto;
    left: 0;
    right: 0;
    width: 100%;
    width: 100vw;
    height: 300px; /* default */
    max-height: 100%;
}

.hfe-flyout-wrapper .hfe-side.bottom {
    top: auto;
    bottom: -100%; /* default */
    left: 0;
    right: 0;
    width: 100%;
    width: 100vw;
    height: 300px; /* default */
    max-height: 100%;
}

/* ===========================================================
 *  Flyout Container
 * ======================================================== */

.hfe-flyout-content {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    line-height: 1.4em;
    color: #fff;
}
/* iOS fix */
@media (max-width:1024px) {
    .hfe-flyout-content {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
    }
}

.hfe-flyout-content::-webkit-scrollbar {
    display: none;
}

.admin-bar .hfe-side {
    margin-top: 32px;
}

/* Dropdown animation CSS */

.hfe-submenu-animation-slide_up .hfe-layout-horizontal .menu-item-has-children > ul.sub-menu {
    -webkit-transform: translateY(20%);
    -ms-transform: translateY(20%);
    transform: translateY(20%);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
}

.hfe-submenu-animation-slide_up .hfe-layout-horizontal .menu-item-has-children:hover > ul.sub-menu {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.hfe-submenu-animation-slide_down .hfe-layout-horizontal .menu-item-has-children > ul.sub-menu {  
    -webkit-transform: translateY(-20%);
    -ms-transform: translateY(-20%);
    transform: translateY(-20%);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
}

.hfe-submenu-animation-slide_down .hfe-layout-horizontal .menu-item-has-children:hover > ul.sub-menu {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

a.hfe-menu-item.elementor-button {
    display: inline-flex;
}

.hfe-nav-menu li.elementor-button-wrapper {
    align-self: center;
}

/* Tablet */
@media only screen and ( max-width: 1024px ) {

    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-horizontal li,
    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu{
        width: 100%;
        margin-right:0px;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-layout-horizontal .hfe-nav-menu__toggle,
    .hfe-nav-menu__breakpoint-tablet .hfe-layout-vertical .hfe-nav-menu__toggle {
        display: block;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-horizontal .hfe-nav-menu {
        visibility: hidden;
        opacity: 0;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-active-menu + .hfe-nav-menu__layout-horizontal .hfe-nav-menu {
        visibility: visible;
        opacity: 1;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-horizontal,
    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-vertical {
        visibility: hidden;
        opacity: 0;
        height: 0;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-active-menu + .hfe-nav-menu__layout-horizontal,
    .hfe-nav-menu__breakpoint-tablet .hfe-active-menu + .hfe-nav-menu__layout-vertical {
        visibility: visible;
        opacity: 1;
        height: auto;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-active-menu.hfe-active-menu-full-width + .hfe-nav-menu__layout-horizontal .hfe-nav-menu {
        position: relative;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-active-menu.hfe-active-menu-full-width + .hfe-nav-menu__layout-horizontal,
    .hfe-nav-menu__breakpoint-tablet .hfe-active-menu.hfe-active-menu-full-width + .hfe-nav-menu__layout-vertical {
        position: absolute;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-horizontal .sub-menu {
        visibility: hidden;
        opacity: 0;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-horizontal .menu-item-has-children .menu-item-has-children .sub-menu {
        top: 0;
        left: 0;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-layout-horizontal .hfe-nav-menu__toggle,
    .hfe-nav-menu__breakpoint-tablet .hfe-layout-vertical .hfe-nav-menu__toggle
    {
        visibility: visible;
        opacity: 1;
        display: block;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-vertical {
        float: none;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-horizontal .hfe-nav-menu .menu-item-has-children:hover > .sub-menu,
    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu__layout-horizontal .hfe-nav-menu .menu-item-has-children:hover > .sub-menu,
    .hfe-nav-menu__breakpoint-none .hfe-nav-menu__layout-horizontal .hfe-nav-menu .menu-item-has-children:hover > .sub-menu {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: none;
        transition: none;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-menu-toggle:hover {
        border:1px dotted;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-menu-toggle {
        border:1px dotted transparent;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-horizontal .sub-menu {
        position: relative;
        height: 0;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-horizontal .sub-menu {
        position: relative;
        height: 0;
        top: 0;
    }

    .hfe-nav-menu__breakpoint-tablet .saved-content,
    .hfe-nav-menu__breakpoint-tablet .sub-menu,
    .hfe-nav-menu__breakpoint-tablet .child {
        left: 0 !important;
        width:auto !important;  
    }

    .hfe-nav-menu .sub-menu {
        min-width: auto;
        margin: 0;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-horizontal .hfe-nav-menu .sub-arrow {
        padding: 10px;
    }

    .hfe-nav-menu__breakpoint-tablet.hfe-submenu-icon-arrow .hfe-nav-menu__layout-horizontal .menu-item-has-children ul a .sub-arrow i:before{
        content: ''; 
    }

    .hfe-nav-menu__breakpoint-tablet.hfe-submenu-icon-classic .hfe-nav-menu__layout-horizontal .menu-item-has-children ul a .sub-arrow i:before{
        content: ''; 
    }

    .hfe-nav-menu__breakpoint-tablet.hfe-submenu-icon-arrow .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a .sub-arrow i:before{
        content: ''; 
    }

    .hfe-nav-menu__breakpoint-tablet.hfe-submenu-icon-classic .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a .sub-arrow i:before{
        content: ''; 
    }

    .hfe-nav-menu__breakpoint-tablet.hfe-submenu-icon-plus .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a .sub-arrow i:before{
        content: '-'; 
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu .sub-menu{
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-submenu-icon-arrow .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a .sub-arrow i:before,
    .hfe-nav-menu__breakpoint-none.hfe-submenu-icon-arrow .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a .sub-arrow i:before{
        content: ''; 
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-submenu-icon-classic .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a .sub-arrow i:before,
    .hfe-nav-menu__breakpoint-none.hfe-submenu-icon-classic .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a .sub-arrow i:before{
        content: ''
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-submenu-icon-arrow .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a.hfe-menu-item .sub-arrow i:before,
    .hfe-nav-menu__breakpoint-none.hfe-submenu-icon-arrow .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a.hfe-menu-item .sub-arrow i:before{
        content: ''; 
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-submenu-icon-classic .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a.hfe-menu-item .sub-arrow i:before,
    .hfe-nav-menu__breakpoint-none.hfe-submenu-icon-classic .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a.hfe-menu-item .sub-arrow i:before{
        content: ''
    }

    /* Effect 1: Fade in and scale up */
    .hfe-submenu-animation-slide_up .hfe-layout-horizontal .menu-item-has-children ul.sub-menu,
    .hfe-submenu-animation-slide_up .hfe-layout-horizontal .menu-item-has-children:hover ul.sub-menu,
    .hfe-submenu-animation-slide_down .hfe-layout-horizontal .menu-item-has-children ul.sub-menu,
    .hfe-submenu-animation-slide_down .hfe-layout-horizontal .menu-item-has-children:hover ul.sub-menu {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }
    
    /*.hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-horizontal .hfe-nav-menu ul.sub-menu ul.sub-menu li a{
        border-left: 16px solid transparent;
    }*/

    .elementor-widget-hfe-nav-menu.hfe-nav-menu__breakpoint-tablet .hfe-nav-menu-layout:not(.hfe-pointer__framed) .menu-item.parent a.hfe-menu-item:after,
    .elementor-widget-hfe-nav-menu.hfe-nav-menu__breakpoint-tablet .hfe-nav-menu-layout:not(.hfe-pointer__framed) .menu-item.parent a.hfe-menu-item:before {
         background-color: unset!important; 
    }

    .elementor-widget-hfe-nav-menu.hfe-nav-menu__breakpoint-tablet .hfe-pointer__framed .menu-item.parent a.hfe-menu-item:before{
        border: 0px!important;
    }

    .elementor-widget-hfe-nav-menu.hfe-nav-menu__breakpoint-tablet .hfe-pointer__text .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__text  .menu-item.parent a.hfe-menu-item:focus{
        -webkit-transform: none!important;
        -ms-transform: none!important;
        transform: none!important;
    }

    .hfe-nav-menu__breakpoint-tablet.hfe-nav-menu__align-justify .hfe-nav-menu__layout-horizontal .hfe-nav-menu li a.hfe-menu-item,
    .hfe-nav-menu__breakpoint-tablet.hfe-nav-menu__align-justify .hfe-nav-menu__layout-horizontal .hfe-nav-menu li a.hfe-sub-menu-item,
    .hfe-nav-menu__breakpoint-tablet.hfe-nav-menu__align-justify .hfe-nav-menu__layout-horizontal .hfe-nav-menu li.elementor-button-wrapper {
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; 
    }
}

/* Mobile */
@media only screen and ( max-width: 767px ) {

    .hfe-nav-menu__breakpoint-mobile.hfe-nav-menu__align-justify .hfe-nav-menu__layout-horizontal .hfe-nav-menu li a.hfe-menu-item,
    .hfe-nav-menu__breakpoint-mobile.hfe-nav-menu__align-justify .hfe-nav-menu__layout-horizontal .hfe-nav-menu li a.hfe-sub-menu-item 
    .hfe-nav-menu__breakpoint-tablet.hfe-nav-menu__align-justify .hfe-nav-menu__layout-horizontal .hfe-nav-menu li.elementor-button-wrapper { 
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; 
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-nav-toggle-yes .hfe-layout-horizontal .hfe-nav-menu__toggle,
    .hfe-nav-menu__breakpoint-mobile.hfe-nav-toggle-yes .hfe-nav-menu__layout-horizontal .hfe-nav-menu {
        visibility: visible;
        opacity: 1;
        display: block;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu__layout-horizontal li.menu-item,
    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu {
        width: 100%
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-nav-toggle-yes .hfe-layout-vertical .hfe-nav-menu__toggle {
        display: inline-block;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu__layout-horizontal .sub-menu {
        position: relative;
        height: 0;
        top: 0;
    }

    .hfe-nav-menu .sub-menu {
        min-width: auto;
        margin: 0;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu__layout-horizontal .hfe-nav-menu .menu-item-has-children:hover > .sub-menu,
    .hfe-nav-menu__breakpoint-none .hfe-nav-menu__layout-horizontal .hfe-nav-menu .menu-item-has-children:hover > .sub-menu {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: none;
        transition: none;
    }

    .hfe-nav-menu__breakpoint-tablet .hfe-nav-menu__layout-vertical {
        float: none;
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-nav-toggle-yes .hfe-layout-vertical .hfe-nav-menu__toggle.hfe-active-menu {
        display: block;
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-nav-toggle-yes .hfe-nav-menu__layout-horizontal,
    .hfe-nav-menu__breakpoint-mobile.hfe-nav-toggle-yes .hfe-nav-menu__layout-vertical {
        visibility: hidden;
        opacity: 0;
        height: 0;  
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-layout-horizontal .hfe-nav-menu__toggle,
    .hfe-nav-menu__breakpoint-mobile .hfe-layout-vertical .hfe-nav-menu__toggle
    {
        visibility: visible;
        opacity: 1;
        display: block;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu__layout-horizontal {
        visibility: hidden;
        opacity: 0;
        height: 0;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu__layout-vertical {
        float: none;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu__layout-horizontal .sub-menu {
        visibility: hidden;
        opacity: 0;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu__layout-horizontal .hfe-nav-menu {
        visibility: hidden;
        opacity: 0;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-active-menu + .hfe-nav-menu__layout-horizontal .hfe-nav-menu {
        visibility: visible;
        opacity: 1;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu__layout-horizontal,
    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu__layout-vertical {
        visibility: hidden;
        opacity: 0;
        height: 0;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-active-menu + .hfe-nav-menu__layout-horizontal,
    .hfe-nav-menu__breakpoint-mobile .hfe-active-menu + .hfe-nav-menu__layout-vertical {
        visibility: visible;
        opacity: 1;
        height: auto;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-active-menu.hfe-active-menu-full-width + .hfe-nav-menu__layout-horizontal .hfe-nav-menu {
        position: relative;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-active-menu.hfe-active-menu-full-width + .hfe-nav-menu__layout-horizontal,
    .hfe-nav-menu__breakpoint-mobile .hfe-active-menu.hfe-active-menu-full-width + .hfe-nav-menu__layout-vertical {
        position: absolute;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu__layout-horizontal .menu-item-has-children .menu-item-has-children .sub-menu {
        top: 0;
        left: 0;
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-submenu-icon-arrow .hfe-nav-menu__layout-horizontal .menu-item-has-children ul a .sub-arrow i:before{
        content: ''; 
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-submenu-icon-classic .hfe-nav-menu__layout-horizontal .menu-item-has-children ul a .sub-arrow i:before{
        content: ''; 
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-submenu-icon-arrow .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a .sub-arrow i:before{
        content: ''; 
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-submenu-icon-classic .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a .sub-arrow i:before{
        content: ''; 
    }

    .hfe-nav-menu__breakpoint-mobile.hfe-submenu-icon-plus .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a .sub-arrow i:before{
        content: '-'; 
    }

    .hfe-nav-menu__breakpoint-none.hfe-submenu-icon-arrow .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a .sub-arrow i:before{
        content: ''; 
    }

    .hfe-nav-menu__breakpoint-none.hfe-submenu-icon-classic .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a .sub-arrow i:before{
        content: ''
    }

    .hfe-nav-menu__breakpoint-none.hfe-submenu-icon-arrow .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a.hfe-menu-item .sub-arrow i:before{
        content: ''; 
    }

    .hfe-nav-menu__breakpoint-none.hfe-submenu-icon-classic .hfe-nav-menu__layout-horizontal .menu-item-has-children .sub-menu-active a.hfe-menu-item .sub-arrow i:before{
        content: ''
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-menu-toggle:hover{
        border:1px dotted;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-menu-toggle{
        border:1px dotted transparent;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu .sub-menu{
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    /* Effect 1: Fade in and scale up */
    .hfe-nav-menu__breakpoint-mobile.hfe-nav-toggle-yes .hfe-effect-1 .hfe-nav-menu {
        -webkit-transform: scale(0.7);
        -ms-transform: scale(0.7);
        transform: scale(0.7);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .hfe-nav-menu__breakpoint-mobile .hfe-nav-menu__layout-horizontal .hfe-nav-menu .sub-arrow {
        padding: 10px;
    }

    .hfe-nav-menu__breakpoint-mobile .menu-is-active.hfe-effect-1 .hfe-nav-menu {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }

    /* Effect 2: Slide from the right */
    .hfe-nav-menu__breakpoint-mobile.hfe-nav-toggle-yes .hfe-effect-2 .hfe-nav-menu {
        -webkit-transform: translateX(20%);
        -ms-transform: translateX(20%);
        transform: translateX(20%);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
        transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
    }

    .hfe-nav-menu__breakpoint-mobile .menu-is-active.hfe-effect-2 .hfe-nav-menu {
        -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }

    /* Effect 3: Slide from the bottom */
    .hfe-nav-menu__breakpoint-mobile.hfe-nav-toggle-yes .hfe-effect-3 .hfe-nav-menu {
        -webkit-transform: translateY(20%);
        -ms-transform: translateY(20%);
        transform: translateY(20%);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .hfe-nav-menu__breakpoint-mobile .menu-is-active.hfe-effect-3 .hfe-nav-menu {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }

    .hfe-nav-menu__breakpoint-mobile .saved-content,
    .hfe-nav-menu__breakpoint-mobile .sub-menu,
    .hfe-nav-menu__breakpoint-mobile .child,
    .hfe-nav-menu__breakpoint-tablet .saved-content,
    .hfe-nav-menu__breakpoint-tablet .sub-menu,
    .hfe-nav-menu__breakpoint-tablet .child{
        left: 0 !important;
        width: auto !important; 
    }

    .hfe-submenu-animation-slide_up .hfe-layout-horizontal .menu-item-has-children ul.sub-menu,
    .hfe-submenu-animation-slide_up .hfe-layout-horizontal .menu-item-has-children:hover ul.sub-menu,
    .hfe-submenu-animation-slide_down .hfe-layout-horizontal .menu-item-has-children ul.sub-menu,
    .hfe-submenu-animation-slide_down .hfe-layout-horizontal .menu-item-has-children:hover ul.sub-menu {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }   

    .elementor-widget-hfe-nav-menu.hfe-nav-menu__breakpoint-tablet .hfe-nav-menu-layout:not(.hfe-pointer__framed) .menu-item.parent a.hfe-menu-item:after,
    .elementor-widget-hfe-nav-menu.hfe-nav-menu__breakpoint-mobile .hfe-nav-menu-layout:not(.hfe-pointer__framed) .menu-item.parent a.hfe-menu-item:after,
    .elementor-widget-hfe-nav-menu.hfe-nav-menu__breakpoint-tablet .hfe-nav-menu-layout:not(.hfe-pointer__framed) .menu-item.parent a.hfe-menu-item:before,
    .elementor-widget-hfe-nav-menu.hfe-nav-menu__breakpoint-mobile .hfe-nav-menu-layout:not(.hfe-pointer__framed) .menu-item.parent a.hfe-menu-item:before {
        background-color: unset!important; 
    }

    .elementor-widget-hfe-nav-menu.hfe-nav-menu__breakpoint-tablet .hfe-pointer__framed .menu-item.parent a.hfe-menu-item:before,
    .elementor-widget-hfe-nav-menu.hfe-nav-menu__breakpoint-mobile .hfe-pointer__framed .menu-item.parent a.hfe-menu-item:before{
        border: 0px!important;
    }

    .elementor-widget-hfe-nav-menu.hfe-nav-menu__breakpoint-tablet .hfe-pointer__text .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__text  .menu-item.parent a.hfe-menu-item:focus,
    .elementor-widget-hfe-nav-menu.hfe-nav-menu__breakpoint-mobile .hfe-pointer__text  .menu-item.parent a.hfe-menu-item:hover, .hfe-pointer__text .menu-item.parent a.hfe-menu-item:focus {
        -webkit-transform: none!important;
        -ms-transform: none!important;
        transform: none!important;
    }
}

.hfe-toggle-no .hfe-layout-expandible .hfe-nav-menu__toggle {
    display: none;
}

/* Icon common CSS */

.hfe-icon {
    display: inline-block;
}

/* Menu Cart CSS */

.hfe-cart-menu-wrap-custom {
    display: inline-block;
    line-height: 1;
}

.hfe-cart-menu-wrap-custom .count, 
.hfe-cart-menu-wrap-custom .count:after {
    border-color: #75c32c;
    color: #75c32c;
}

.hfe-cart-menu-wrap-custom .count {
    font-weight: bold;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    min-width: 2.1em;
    min-height: 2.1em;
    font-size: 0.860em;
    line-height: 1.8;
    border-radius: 0.3em 0.3em 0.3em 0.3em;
    border-width: 2px;
    border-style: solid;
    padding: 12px 24px;
}

/* Default skin for cart*/
.hfe-cart-menu-wrap-default {
    display: inline-block;
    line-height: 1;
}

.hfe-cart-menu-wrap-default .count, 
.hfe-cart-menu-wrap-default .count:after {
    border-color: #75c32c;
    color: #75c32c;
}

.hfe-cart-menu-wrap-default .count {
    font-weight: bold;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    min-width: 2.1em;
    min-height: 2.1em;
    font-size: 0.860em;
    line-height: 1.8;
    border-radius: 0em 0em 0.3em 0.3em;
    border-width: 2px;
    border-style: solid;
    padding: 0 .4em;
}

.hfe-cart-menu-wrap-default .count:after {
    bottom: 100%;
    margin-bottom: 0;
    height: .8em;
    width: .8em;
    left: 50%;
    transform: translateX(-50%);
    top: -.6em;
    content: ' ';
    position: absolute;
    pointer-events: none;
    border-width: 2px;
    border-style: solid;
    border-top-left-radius: 10em;
    border-top-right-radius: 10em;
    border-bottom: 0;
}

.elementor-widget-hfe-cart:not(.hfe-menu-cart--show-subtotal-yes) .hfe-menu-cart__toggle .elementor-button-text {
    display: none; 
}

.elementor-widget-hfe-cart.toggle-icon--bag-light .hfe-menu-cart__toggle .elementor-button-icon i:before {
    content: '\e8e3'; 
}

.elementor-widget-hfe-cart.toggle-icon--bag-medium .hfe-menu-cart__toggle .elementor-button-icon i:before {
    content: '\e8e4'; 
}

.elementor-widget-hfe-cart.toggle-icon--bag-solid .hfe-menu-cart__toggle .elementor-button-icon i:before {
    content: '\e8e5'; 
}

.hfe-menu-cart__toggle .elementor-button {
    background: transparent;
    border-radius: 0;
    color: #818a91;
    border: 1px solid;
    border-color: inherit;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; 
}

.hfe-menu-cart__toggle .elementor-button-text {
    margin-right: 0.3em; 
}

.hfe-menu-cart__toggle .elementor-button-icon {
    position: relative;
    -webkit-transition: color 0.1s;
    -o-transition: color 0.1s;
    transition: color 0.1s; 
}

.hfe-menu-cart--items-indicator-bubble .hfe-menu-cart__toggle .elementor-button-icon[data-counter]:before {
    content: attr(data-counter);
    display: block;
    position: absolute;
    min-width: 1.6em;
    height: 1.6em;
    line-height: 1.5em;
    top: -0.7em;
    right: -0.7em;
    border-radius: 100%;
    color: #fff;
    background-color: #d9534f;
    text-align: center;
    font-size: 10px; 
}

.hfe-menu-cart--items-indicator-plain .hfe-menu-cart__toggle .elementor-button-icon[data-counter]:before {
    content: attr(data-counter);
    display: inline-block;
    font-weight: normal; 
}

.hfe-menu-cart--empty-indicator-hide .hfe-menu-cart__toggle .elementor-button .elementor-button-icon[data-counter]:before {
    content: none;
    display: none; 
}

.hfe-menu-cart__toggle .elementor-button-icon {
    -webkit-box-ordinal-group: 16;
    -webkit-order: 15;
    -ms-flex-order: 15;
    order: 15;
}

.hfe-menu-cart__toggle.elementor-button-wrapper .elementor-button {
    color:#4d4848;
}

/*Search Box CSS*/

.hfe-search-button-wrapper {
    display: block;
    cursor: pointer;
    vertical-align: middle;
    text-align: right;
}

.hfe-search-form__container {
    display: flex;
    overflow: hidden;
}

.hfe-search-form__input {
    flex-basis: 100%;
}

.hfe-search-icon-toggle i.fa-search {
    display: inline-block;
    position: absolute;
    width: 20px;
    box-sizing: content-box;
    padding: 10px;
}

.hfe-search-icon-toggle i:before {
    vertical-align: middle;
}
/*search icon toggle css*/

.hfe-search-button-wrapper input[type=search] {
    border: 0;
    padding: 10px;
    cursor: pointer;
    border-radius: 0;
    transition: all .5s;
    box-sizing: border-box;
    outline: none;
}

.hfe-search-button-wrapper button {
    border: none;
    border-radius: 0;
    padding: 0;
}

.hfe-search-button-wrapper input[type=search]:focus {
    cursor: auto;
    border: none;
    box-shadow: none;
}

.hfe-search-icon-toggle input:-moz-placeholder,
.hfe-search-icon-toggle input::-webkit-input-placeholder {
    color: transparent;
}

.hfe-search-layout-icon .hfe-search-icon-toggle .hfe-search-form__input {
    background-color: unset;
    height: 100%;
}

.hfe-search-button-wrapper.hfe-input-focus {
    display: flex;
}

.hfe-search-form__container .hfe-search-submit {
    min-width: 3%;
    position:relative;
}

.hfe-search-form__container button#clear,
.hfe-search-icon-toggle button#clear {
    visibility: hidden;
    position: absolute;
    right:0; 
    top:0;
    padding: 0 8px;
    font-style: normal;
    user-select: none;
    cursor: pointer;
    bottom:0;
    margin-right:10px;
    background-color: transparent;
}

.hfe-search-button-wrapper input[type="search"]::-webkit-search-decoration,
.hfe-search-button-wrapper input[type="search"]::-webkit-search-cancel-button,
.hfe-search-button-wrapper input[type="search"]::-webkit-search-results-button,
.hfe-search-button-wrapper input[type="search"]::-webkit-search-results-decoration { 
    display: none; 
}

.hfe-search-form__container button#clear-with-button{
    visibility: hidden;
    position: absolute;
    top: 0;
    padding: 0 8px;
    font-style: normal;
    font-size: 1.2em;
    user-select: none;
    cursor: pointer;
    bottom:0;
    background-color: transparent;
}

.hfe-search-button-wrapper, 
.hfe-search-form__container,
.hfe-search-icon-toggle,
.hfe-search-button-wrapper .hfe-search-icon-toggle i,
.hfe-search-button-wrapper .hfe-search-icon-toggle i:before {
    position: relative;
}

.hfe-search-button-wrapper .hfe-search-icon-toggle .hfe-search-form__input {
    position: absolute;
    width: 0;
    right: 0;
    background-color: transparent;
}

.hfe-search-icon-toggle {
    width: 100%!important;
}

.hfe-search-button-wrapper.hfe-input-focus .hfe-search-icon-toggle .hfe-search-form__input{
    position: absolute;
    width: 250px;
    right: 0;
}

.hfe-search-layout-text .hfe-search-form__container,
.hfe-search-layout-icon_text .hfe-search-form__container,
.hfe-search-layout-text .hfe-search-form__container .hfe-search-form__input,
.hfe-search-layout-icon_text .hfe-search-form__container .hfe-search-form__input {
    width: 100%;
}

.hfe-search-icon-toggle {
    line-height: 1.5;
}

/*-------Navigation menu active parent---------*/
.hfe-pointer__double-line.hfe-animation__slide .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after,.hfe-pointer__double-line.hfe-animation__slide .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__underline.hfe-animation__slide .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after,
 .hfe-pointer__overline.hfe-animation__slide .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__overline.hfe-animation__fade .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before  {
    width: 100%;
    left: 0;
    opacity:1;
}

.hfe-pointer__double-line.hfe-animation__drop-out .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__underline.hfe-animation__drop-out .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__overline.hfe-animation__drop-out .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after,.hfe-pointer__double-line.hfe-animation__drop-in .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__underline.hfe-animation__drop-in .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__overline.hfe-animation__drop-in .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after {
    bottom: 0px;
    opacity:1;
}
.hfe-pointer__double-line.hfe-animation__drop-out .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__underline.hfe-animation__drop-out .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__overline.hfe-animation__drop-out .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__double-line.hfe-animation__drop-in .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__underline.hfe-animation__drop-in .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__overline.hfe-animation__drop-in .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    top: 0px;
    opacity: 1;
}

.hfe-pointer__double-line.hfe-animation__grow .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__double-line.hfe-animation__grow .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__underline.hfe-animation__grow .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__underline.hfe-animation__grow .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__overline.hfe-animation__grow .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__overline.hfe-animation__grow .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after {
    height: 3px;
    width: 100%;
    left: 0;
    opacity: 1;
}

.hfe-pointer__framed.hfe-animation__grow .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__framed.hfe-animation__shrink .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.hfe-pointer__framed.hfe-animation__fade .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__framed.hfe-animation__draw .menu-item.parent.current-menu-item a.hfe-menu-item:before, .hfe-pointer__underline.hfe-animation__fade .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after, .hfe-pointer__double-line.hfe-animation__fade .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, .hfe-pointer__double-line.hfe-animation__fade .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after {
    opacity:1;
}

.hfe-pointer__framed.hfe-animation__corners .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, 
.hfe-pointer__framed.hfe-animation__corners .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after,
.hfe-pointer__framed.hfe-animation__draw .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):before, 
.hfe-pointer__framed.hfe-animation__draw .menu-item.parent.current-menu-item a.hfe-menu-item:not(:hover):not(:focus):not(.current-menu-item):not(.highlighted):after {
    border-width:3px;
    width:100%;
    opacity:1;
    height:100%;
}
