@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;400;500;600;700&display=swap');
body{
    font-family: 'Popins', sans-serif;
    background-color: #FFFFFF;
}
body::-webkit-scrollbar {
    width: 0.4em;
}
   
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
   
body::-webkit-scrollbar-thumb {
  background-color: #222732;
  outline: 1px solid #222732;
}

.description {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.row1 {  
    display: flex;
    /* position: relative; */
    flex-wrap: wrap;
    padding: 50px;
    background-image: linear-gradient(to bottom, #222732, #232632, #242432, #252331, #272130, #261e2b, #251b27, #231822, #1d141a, #170e13, #0e070a, #000000);
}
  .side {
    flex: 60%;
    padding: 40px;
  }
  
  /* Main column */
  .main {
    flex: 40%;
  }
  
  .l-img {
    padding-left: 10px;
    width: 426px;
    height: 568px;
  }

  @media screen and (max-width: 700px) {
    .row {   
      flex-direction: column;
    }
  }

.welcome3 {
    color: #ffffff;
    font-size: 2.4666666666667rem;
    font-weight: 700;
}

.desc3{
    color: #ee0979;
    font-size: 1.8666666666667rem;
    font-weight: 700;
    line-height: 1.4;
}

.desc4{
    border: 0;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
    color: #FFFFFF;
}